import Cookies from 'js-cookie';
import type { PFAppEnum } from '~/types';

const CSRF_COOKIE_NAME = 'bp_csrftoken';
const LOGIN_CSRF_COOKIE_NAME = 'login_csrftoken';

namespace Globals {
  export function getDocument() {
    return document;
  }

  export function getWindow(): Window &
    typeof globalThis & { [key: string]: any } {
    return window;
  }

  export function getLocation() {
    return getWindow().location;
  }

  export function getApp(): Record<string, any> {
    return window.app ?? {};
  }

  export function updateApp(app: any) {
    window.app = { ...getApp(), ...app };
  }

  export function getAppSettings(): Record<string, any> {
    return getApp().settings ?? {};
  }

  export function getAppEnv(): Record<string, any> {
    return getApp().env ?? {};
  }

  export function getApiUrl() {
    return getAppEnv().API_FE_URL;
  }

  export function getApiDocUrl() {
    return getAppEnv().API_DOC_URL;
  }

  export function getKBUrl() {
    return getAppEnv().KNOWLEDGE_BASE_URL;
  }

  export function getFroalaKey() {
    return getAppEnv().FROALA_KEY;
  }

  export function getCommunityUrl(app: PFAppEnum) {
    return getAppEnv().APP_COMMUNITY_URLS?.[app] ?? '';
  }

  export function getCsrfToken() {
    return Cookies.get(CSRF_COOKIE_NAME);
  }

  export function getLoginCsrfToken() {
    return Cookies.get(LOGIN_CSRF_COOKIE_NAME);
  }
}

export default Globals;
