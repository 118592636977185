import React, { useEffect } from 'react';
import { ComponentLoader } from 'ui/loaders';
import logo from 'images/logo/PF-Logo-2x.png';
import { getParamsObject } from '@bluepoint/common/utils';
import { Helmet } from 'react-helmet';
import { auth } from '~/stores/AuthStore';
import Body from './Body';

const LogoutWithSsoHandler = () => {
  useEffect(() => {
    auth.clearAccessToken();
    const params = getParamsObject(window.location.search, true);
    window.location.replace(params.logout_url ?? '/user/login');
  }, []);

  return (
    <>
      <Helmet>
        <title>BluePoint - {gettext('Logout')}</title>
      </Helmet>
      <Body>
        <div
          style={{
            lineHeight: 1.1,
            fontSize: 170,
            marginTop: 20,
            marginBottom: 10,
            textAlign: 'center',
          }}
        >
          <img src={logo} alt="" />
        </div>
        <div className="text-center">
          <h3>{gettext('Logging out...')}</h3>
          <ComponentLoader />
        </div>
        <p className="m-t">
          <small>
            {gettext('BluePoint renewable asset management software')} <br />
            <a href="https://powerfactos.com/">Power Factors LLC</a> &copy;{' '}
            {new Date().getFullYear()}
          </small>
        </p>
      </Body>
    </>
  );
};

export default LogoutWithSsoHandler;
