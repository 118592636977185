import React from 'react';
import type { IconComponent } from 'ui/icons/IconsTypes';

export const CalendarIcon: IconComponent = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 1C11 0.723858 10.7761 0.5 10.5 0.5C10.2239 0.5 10 0.723858 10 1V2H6V1C6 0.723858 5.77614 0.5 5.5 0.5C5.22386 0.5 5 0.723858 5 1V2H3.72222C2.77107 2 2 2.77107 2 3.72222V6.5V12.2778C2 13.2289 2.77107 14 3.72222 14H12.2778C13.2289 14 14 13.2289 14 12.2778V6.5V3.72222C14 2.77107 13.2289 2 12.2778 2H11V1ZM13 6V3.72222C13 3.32335 12.6767 3 12.2778 3H11V4C11 4.27614 10.7761 4.5 10.5 4.5C10.2239 4.5 10 4.27614 10 4V3H6V4C6 4.27614 5.77614 4.5 5.5 4.5C5.22386 4.5 5 4.27614 5 4V3H3.72222C3.32335 3 3 3.32335 3 3.72222V6H13ZM3 7H13V12.2778C13 12.6767 12.6767 13 12.2778 13H3.72222C3.32335 13 3 12.6767 3 12.2778V7Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CalendarIcon;
