import React from 'react';
import type { IconComponent } from 'ui/icons/IconsTypes';

export const CalculatorIcon: IconComponent = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2H12C12.5523 2 13 2.44772 13 3V12.5C13 13.0523 12.5523 13.5 12 13.5H4C3.44772 13.5 3 13.0523 3 12.5V3C3 2.44772 3.44772 2 4 2ZM2 3C2 1.89543 2.89543 1 4 1H12C13.1046 1 14 1.89543 14 3V12.5C14 13.6046 13.1046 14.5 12 14.5H4C2.89543 14.5 2 13.6046 2 12.5V3ZM6 7H7V8H6V7ZM7 9H6V10H7V9ZM6 11H7V12H6V11ZM5 7H4V8H5V7ZM4 9H5V10H4V9ZM5 11H4V12H5V11ZM8 7H9V8H8V7ZM11 7H10V8H11H12V7H11ZM10 9H11H12V10H11H10V9ZM9 9H8V10H9V9ZM8 11H9V12H8V11ZM11 11H10V12H11H12V11H11ZM5 4H11V5H5V4ZM4 3H5H11H12V4V5V6H11H5H4V5V4V3Z"
        fill="currentColor"
      />
    </svg>
  );
};
