import Globals from '~/common/globals';

let scrollBarSizeCached;
export function getScrollBarSize(fresh) {
  if (typeof document === 'undefined') {
    return 0;
  }

  if (fresh || scrollBarSizeCached === undefined) {
    const inner = document.createElement('div');
    inner.style.width = '100%';
    inner.style.height = '200px';
    const outer = document.createElement('div');
    const outerStyle = outer.style;
    outerStyle.position = 'absolute';
    outerStyle.top = 0;
    outerStyle.left = 0;
    outerStyle.pointerEvents = 'none';
    outerStyle.visibility = 'hidden';
    outerStyle.width = '200px';
    outerStyle.height = '150px';
    outerStyle.overflow = 'hidden';
    outer.appendChild(inner);
    document.body.appendChild(outer);
    const widthContained = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    let widthScroll = inner.offsetWidth;

    if (widthContained === widthScroll) {
      widthScroll = outer.clientWidth;
    }

    document.body.removeChild(outer);
    scrollBarSizeCached = widthContained - widthScroll;
  }

  return scrollBarSizeCached;
}

export const getTitlePrefix = () => {
  let env = '';
  if (
    typeof process !== 'undefined' &&
    process.env &&
    process.env.CURRENT_ENV
  ) {
    env = process.env.CURRENT_ENV;
  } else if (Globals.getAppEnv().CURRENT_ENV) {
    env = Globals.getAppEnv().CURRENT_ENV;
  }
  if (env === 'STAGING') {
    return 'Staging';
  }
  if (env === 'LOCAL') {
    return 'Local';
  }
  return 'Bluepoint';
};

export const buttonLoading = (selector, msg) => {
  let $selector = null;
  if (typeof selector === 'string' || selector instanceof String) {
    $selector = $(selector);
  } else {
    $selector = selector;
  }
  if (!msg) {
    msg = gettext('Saving...');
  }
  msg = `<i class="fa fa-spinner fa-spin"></i>&nbsp;${msg}`;
  const preHTML = $selector.html();
  $selector.html(msg);
  $selector.attr('preHTML', preHTML);
  $selector.addClass('disabled');
  $selector.attr('disabled', true);
};

export const buttonLoaded = selector => {
  let $selector = null;
  if (typeof selector === 'string' || selector instanceof String) {
    $selector = $(selector);
  } else {
    $selector = selector;
  }
  const preHTML = $selector.attr('preHTML');
  $selector.html(preHTML);
  $selector.removeClass('disabled');
  $selector.attr('disabled', false);
};
