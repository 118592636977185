import axios from 'axios';
import Globals from '~/common/globals';
import { request as httpRequest } from './legacy-http';

export const urlFactory =
  apiPath =>
  (path = '') => {
    return `${location.origin}/${apiPath}${path}`;
  };

export const commonConfig = {
  responseType: 'json',
};

export const commonHeaders = {
  'X-CSRFToken': Globals.getCsrfToken(),
};

export const formHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
};

export const toFormData = data => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));
  return formData;
};

export const toURLSearchParams = data => {
  const params = new URLSearchParams();
  Object.entries(data).forEach(([key, value]) =>
    Array.isArray(value)
      ? value.forEach(subVal => params.append(key, subVal))
      : params.append(key, value),
  );
  return params;
};

export const formRequest = (
  { data, headers, ...config },
  responseDecoder,
  errorDecoder,
) =>
  httpRequest(
    {
      ...commonConfig,
      ...config,
      headers: {
        ...commonHeaders,
        ...formHeaders,
        ...headers,
      },
      data: toFormData(data),
    },
    responseDecoder,
    errorDecoder,
  );

export const request = (
  { headers, ...config },
  responseDecoder,
  errorDecoder,
) =>
  httpRequest(
    {
      ...commonConfig,
      ...config,
      headers: {
        ...commonHeaders,
        ...headers,
      },
    },
    responseDecoder,
    errorDecoder,
  );

/**
 * @param err
 * @returns {err is AxiosError}
 */
export function isApiError(err) {
  return axios.isAxiosError(err);
}
