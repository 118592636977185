import React from 'react';
import type { IconComponentProps } from 'ui/icons/IconsTypes';

export const EventIcon = (props: IconComponentProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM8 4.66675C8.27614 4.66675 8.5 4.89061 8.5 5.16675V8.16675C8.5 8.44289 8.27614 8.66675 8 8.66675C7.72386 8.66675 7.5 8.44289 7.5 8.16675V5.16675C7.5 4.89061 7.72386 4.66675 8 4.66675ZM7.99999 11.4C8.38659 11.4 8.69999 11.0866 8.69999 10.7C8.69999 10.3134 8.38659 10 7.99999 10C7.61339 10 7.29999 10.3134 7.29999 10.7C7.29999 11.0866 7.61339 11.4 7.99999 11.4Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EventIcon;
